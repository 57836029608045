import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { reduxRegistry } from './registry';

const sagaMiddleware = createSagaMiddleware();
const middleware: (SagaMiddleware<object> | Middleware)[] = [sagaMiddleware];

if (!config.disableReduxLogger) {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: state => state,
  middleware,
  devTools: config.reduxDevTools,
});

export type RootState = { [k: string]: unknown };

reduxRegistry.reducerChangeListener = (reducers): void => {
  store.replaceReducer(combineReducers(reducers));
};

reduxRegistry.sagaChangeListener = (sagas): void => {
  for (const saga of sagas) {
    sagaMiddleware.run(saga);
  }
};

export { reduxRegistry };
