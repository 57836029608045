import React, { createElement, useMemo } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import {
  CssBaseline,
  StyledEngineProvider as TaxfixUIStylesProvider,
  ThemeProvider as TaxfixUIThemeProvider,
  internalDarkTheme,
  internalTheme,
} from '@taxfix/ui';

import Avo from './avo';
import App from './components/app';
import Messages from './components/messages';
import history from './history';
import { useDarkMode } from './hooks/use-dark-mode';
import { IntlStringsGate } from './intl/intl-strings-gate';
import { store } from './redux';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from './theme';
import './theme/fonts.css';

Avo.initAvo({ env: config.avoEnv }, null);

const persistor = persistStore(store);

const AppContainer: React.FC = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProviders>
        <IntlStringsGate>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App />
            </QueryParamProvider>
          </Router>
          <Messages />
        </IntlStringsGate>
      </ThemeProviders>
    </PersistGate>
  </Provider>
);

const ThemeProviders: React.FC = ({ children }) => {
  const prefersDarkMode = useDarkMode();

  const theme = useMemo(
    () => (prefersDarkMode ? internalDarkTheme : internalTheme),
    [prefersDarkMode],
  );

  return (
    <TaxfixUIStylesProvider injectFirst>
      <TaxfixUIThemeProvider theme={theme}>
        <StyledComponentsThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyle theme={theme} />
          {children}
        </StyledComponentsThemeProvider>
      </TaxfixUIThemeProvider>
    </TaxfixUIStylesProvider>
  );
};

render(createElement(AppContainer), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
